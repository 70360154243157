<template>
    <div id="wrapper">
        <div class="PC-wrapper">
            <el-dialog
                title=""
                :visible.sync="DialogVisible"
                width="30%"
                :modal="true"
                @close="sendParentFun"
                :modal-append-to-body="false"
                :show-close="false"
                :close-on-click-modal="false"
                center>
                <div class="login-box">
                    <h1>欢迎登录云即玩游戏</h1>
                    <div class="login-type">
                        <img style="margin-right: 0.625rem" :src="bindIconByQQ" @click="loginByQQ" alt="">
                        <img :src="bindIconByWeixin" @click="loginByWechat" alt="">
                    </div>
                    <div class="closeBtn">
                        <img src="../../assets/images/btn_icon_guangbi_default.png" alt="" @click="DialogVisible = false">
                    </div>
                </div>
            </el-dialog>
        </div>

        <div class="Phone-weapper">
            <el-dialog
                title=""
                :visible.sync="PCDialogVisible"
                width="80%"
                :modal="true"
                @close="sendParentFunPhone"
                :modal-append-to-body="false"
                :show-close="false"
                center>
                <div class="phone-login-box">
                    <h1>欢迎登录云即玩游戏</h1>
                    <div class="login-type">
                        <img style="margin-right: 0.625rem" :src="bindIconByQQ" @click="loginByQQ" alt="">
                        <img :src="bindIconByWeixin" @click="loginByWechat" alt="">
                    </div>
                    <div class="closeBtn">
                        <img src="../../assets/images/btn_icon_guangbi_default.png" alt="" @click="PCDialogVisible = false">
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoginDialog",
    data () {
        return {
            DialogVisible: false,
            PCDialogVisible: false,
            bindIconByQQ: require('../../assets/images/QQ.png'),
            bindIconByWeixin: require('../../assets/images/weixin.png'),
        }
    },
    created() {

        if(this.centerDialogVisible) {
            this.DialogVisible = this.centerDialogVisible
        }
    },
    watch: {
        // 监听父组件值的变化
        centerDialogVisible: {
            handler(newValue, oldValue) {
                this.DialogVisible = newValue
            },
            deep: true
        },
        PCcenterDialogVisible: {
            handler(newValue, oldValue) {
                this.PCDialogVisible = newValue
            },
            deep: true
        }
    },
    methods: {
        loginByQQ () {
            var isHttps = this.Public.isHttps() ? "https" : "http"
            window.qc.Login.showPopup({
                appId: "101921069",
                redirectURI: isHttps + "://h5.yunjiwan.com/login?gameId="+this.gameId  //登录成功后会自动跳往该地址
            });
        },
        loginByWechat () {
            // 微信APPID
            var appid = "wxf75cc79fe1773116";
            var isHttps = this.Public.isHttps() ? "https" : "http"
            // 回调地址
            var redirect_uri = isHttps + "%3a%2f%2fh5.yunjiwan.com%2floginWx%3fgameId%3d" + this.gameId
            var url = "https://open.weixin.qq.com/connect/qrconnect?appid="+appid+"&redirect_uri="+redirect_uri+"&response_type=code&scope=snsapi_login#wechat_redirect"
            window.location.href = url
        },
        // 传值给父组件
        sendParentFun() {
            this.$emit('childFun', false)
        },
        sendParentFunPhone() {
            this.$emit('childFunPhone', false)
        }
    },
    props: [
        'centerDialogVisible', 'gameId', 'PCcenterDialogVisible'
    ]
}
</script>

<style>
    .PC-wrapper .el-dialog {
        width: 2.60rem !important;
        height: 1.5625rem;
        border-radius: 16px;
    }

    .Phone-weapper .el-dialog {
        border-radius: 16px;
    }

    .PC-wrapper .el-dialog--center .el-dialog__body, .Phone-weapper .el-dialog--center .el-dialog__body {
        padding: 0 !important;
    }

    .PC-wrapper .el-dialog__header, .Phone-weapper .el-dialog__header{
        padding: 0 !important;
    }
</style>
<style scoped lang="less">
    .login-box {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding-top: 0.15625rem;

        h1 {
            font-size: 0.125rem;
            font-family: Microsoft YaHei;
            color: #383838;
            text-align: center;
        }

        .login-type {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 0.2rem;

            img {
                width: 0.521rem;
                height: 0.521rem;
            }
        }

        .closeBtn {
            display: inline-block;
            position: absolute;
            top: 5px;
            right: -0.42rem;
            width: 0.266rem;
            height: 0.266rem;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .phone-login-box {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding-top: 0.45625rem;

        h1 {
            font-size: 0.48875rem;
            font-family: Microsoft YaHei;
            color: #383838;
            text-align: center;
        }

        .login-type {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: .5rem;
            padding-bottom: .5rem;

            img {
                width: 2rem;
                height: 2rem;
            }
        }

        .closeBtn {
            display: inline-block;
            position: absolute;
            top: 5px;
            right: -0.72rem;
            width: 0.566rem;
            height: 0.566rem;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
</style>